.hero{
    height: 90vh;
}
.image-container img.hero-image{
    width: auto;
    height: 80vh;
    max-height: 600px;
}

.right{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.social-landing-connect-container{
    display: inline-flex;
    flex-direction: column;
}


.social-icons-container{
    display: inline-flex;
    margin: 8px;
    border-radius: 50%;
}

.social-bottom-text-container{
    display: inline-flex;
}

.social-bottom-text-container p{
    margin: 8px;
}

.social-icon{
    width: 24px;
    border-radius:50%;
}

.hero-intro-title{
    margin: 16px;
}

.title-prefix{
    display:inline-flex;
    align-items: center;
}
/* 
.title-prefix-1 h1{
    color: var(--p-dark);
    font-size: 6rem;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
    margin: 0;
} */

/* .title-prefix-2 h2{
    font-size: 3.5rem;
    letter-spacing: 0.1rem;
    font-weight: 600;
    margin: 0;
}
*/
.title-main{
    font-size: 3.5rem;
    letter-spacing: 0.1rem;
    margin: 0;
    text-transform: capitalize;
}

.hero-about-text{
    margin: 32px 16px;
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
    font-weight: 200;
}
