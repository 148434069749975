:root {
  --bgcolor: #f3f4fb;
  --text-color: #181818;
  --primary: #FFAF29;
  --p-dark: #c78000;
  --p-light: #ffe15e;
  --line-height:1.2;
  --secondary: #8539BE;
  color-scheme: light dark;
}
@media (prefers-color-scheme: dark) {
  :root {
      --bgcolor:#121212;
      --text-color: #F9FAFB;
  }
  img {
    filter: brightness(.9) contrast(0.9);
  }
}
body {
  margin: 0;
  font-family: "Roboto",-apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: var(--bgcolor); */
  /* color: var(--text-color); */
  line-height: var(--line-height);
}
::-moz-selection { /* Code for Firefox */
  color: white;
  background: var(--secondary);
}

::selection {
  color: white;
  background: var(--p-light);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

